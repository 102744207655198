.phones_characteristics {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid #cdcfd2;
    min-height: 100px;
}

.phones_characteristics_titles {
    color: #a4a9b9;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}

.row_image {
    width: 22px;
    height: 22px;
}

.hidden {
    display: none;
}
