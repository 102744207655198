* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto";
}

body {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
}

main {
    display: flex;
    justify-content: center;
    text-align: center;
    background: #fff;
}
