.wrapper {
    background: #fff;
    font-size: 18px;
    font-weight: 500;
    width: 1440px;
}

.phones_head {
    height: 370px;
    padding: 20px 165px 0px 165px;
}

.phones_smartphones {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 1100px;
    min-height: 120px;
}

.phones_title {
    width: 550px;
    text-align: start;
    color: #828286;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
}

.phones_models {
    display: flex;
    justify-content: flex-start;
    align-items: last baseline;
}

.phones_differences {
    text-align: start;
    color: #0d5adc;
    font-weight: 400;
    line-height: 60px;
}

.phones_data {
    display: block;
    background: #f4f9fc;
    padding: 0 165px 100px 165px;
}

.first_column {
    width: 255px;
    margin-right: 50px;
}

.second_column {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: first baseline;
    gap: 20px;
}
