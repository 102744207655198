nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.nav_navbar {
    display: flex;
    align-items: center;
    padding-left: 165px;
    padding-right: 165px;
    border-bottom: 1px solid #a6a5a9;
    width: 1440px;
    height: 100px;
    background: #fff;
}

.nav_catalog {
    width: 550px;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.9px;
}

.nav_catalog a {
    color: #0d5adc;
}

.nav_account {
    width: 550px;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.24px;
}

.nav_account a {
    color: #3b4157;
}

li {
    list-style-type: none;
    margin-right: 20px;
}

a {
    text-decoration: none;
}

nav a:hover,
nav a.active {
    color: #a6a5a9;
    font-weight: 700;
}

.image_account {
    width: 17px;
    height: 17px;
}
